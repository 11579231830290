
import { detailAPI, editAPI } from './api'
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { toResult } from '@/utils/toResult'
import moment from 'moment'
import { ElMessage } from 'element-plus'
import { btnName } from '@/utils/index'
export default defineComponent({
  name: 'LockTheCar',
  setup() {
    const data = reactive({
      loading: false,
      formData: ref<any>({
        isLock: false,
        offTimeout: 10
      }),
      resData: ref<any>({})
    })

    const _t = moment(Date.now()).format('YYYY-MM-DD')
    const defaultTime = moment(`${_t} 04:00`)

    ;(async () => {
      data.loading = true
      const [err, res] = await toResult(detailAPI({}))
      data.loading = false
      if (err) return false
      console.log(res)
      data.resData = res || {}
      data.formData.isLock = res.enabled || false
      data.formData.offTimeout = res.offTimeout || 10
      data.formData.cronExpress = moment(`${_t} ${res.cronExpress}`) || defaultTime

      console.log(moment(res.cronExpress))
      console.log(moment(`${_t} ${res.cronExpress}`))
    })()

    const onSubmit = async () => {
      if (!data.formData.cronExpress) {
        ElMessage.warning('请设置时间')
        return
      }
      const p = {
        cronExpress: moment(data.formData.cronExpress).format('HH:mm'),
        enabled: data.formData.isLock,
        offTimeout: data.formData.offTimeout
      }
      data.loading = true
      const [err, res, msg] = await toResult(editAPI(p))
      data.loading = false
      if (err) return false
      ElMessage.success(msg)
      console.log(res)
    }

    return {
      ...toRefs(data),
      btnName,
      onSubmit
    }
  }
})
