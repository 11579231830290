/*
 * @Description:
 * @Author: yaoxiaoxiao
 * @RearEnd:
 * @Date: 2021-11-05 14:28:36
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-11-05 14:28:36
 */

import service from '@/utils/request'

export const editAPI = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/system/carAutoLockSettings/edit',
    data: pramas
  })
export const detailAPI = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/system/carAutoLockSettings/detail',
    data: pramas
  })
